<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="图片" prop="url">
        <div class="ant-upload-preview" @click="$refs.upImg.edit(form.url)" >
          <div class="mask" :style="form.url ? 'opacity: 0' : 'opacity: 1'">
            <a-icon type="plus" />
          </div>
          <img :src="form.url" v-if="form.url"/>
        </div>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="genusId">
        <a-select v-model="form.genusId" placeholder="请选择">
          <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" placeholder="请输入"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
      <up-img-modal ref="upImg" :width="750" :height="250" @ok="setImg"/>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getbanner, addbanner, editbanner, listbannerGenus } from '@/api/system/banner'
import upImgModal from '@/components/upImgModal'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    upImgModal
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      typeList: [],
      // 表单
      form: {
        description: '',
        genusId: undefined,
        id: 0,
        remark: '',
        title: '',
        url: ''
      },
      open: false,
      rules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        url: [{ required: true, message: '图片不能为空', trigger: 'blur' }],
        genusId: [{ required: true, message: '请选择轮播图类型', trigger: ['blur', 'change'] }]
        // remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }],
        // sort: [{ required: true, message: '序号不能为空', trigger: 'blur' }]
      },
      previewVisible: false,
      previewImage: '',
      fileList: []
    }
  },
  filters: {
  },
  created () {
    listbannerGenus().then(response => {
      this.typeList = response.data
    })
  },
  computed: {
  },
  watch: {
  },
  methods: {
    setImg (url) {
      console.log(url)
      this.form.url = url
    },
    change(value, dateString) {
      console.log(value, dateString)
      this.form.publishTime = dateString
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        description: '',
        genusId: undefined,
        id: 0,
        remark: '',
        title: '',
        url: ''
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      getbanner({ id: configId }).then(response => {
        this.form = response.data
        this.fileList = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.url
        }]
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            editbanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addbanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
